<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          Type
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-radio-group v-model="type" row @change="onChangeType">
            <v-radio label="Sale" value="sale"></v-radio>
            <v-radio label="Purchase" value="purchase"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        flat
        dense
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="listInvoice"
        dense
        :search="search"
        item-key="number"
      >
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.totalAmount="{ item }">
          {{ formatPrice(item.totalAmount) }}
        </template>
        <template v-slot:item.pphAmount="{ item }">
          {{ formatPrice(item.pphAmount) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "query-pph",
  data: () => ({
    type: "",
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Inv #",
        value: "number",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Customer",
        value: "name",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Total",
        value: "totalAmount",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "PPH",
        value: "pphAmount",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
  }),

  computed: {
    ...mapState("pph", ["listInvoice"]),
  },

  methods: {
    async onChangeType(val) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("pph/queryInvoice", val)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
